import { useEffect, useState, useRef } from "react";
// import { PlayIcon, PauseIcon } from "@heroicons/react/solid";

import CSS from "csstype";
import ReactPlayer from "react-player";
import "./App.css";
import LoadingIndicator from "./Loading";
const ONESECOND = `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`;

interface Station {
  id: number | undefined;
  name: string | undefined;
  icecast_stream: string | undefined;
}

interface Program {
  id: number | null;
  name: string | null | undefined;
  website_url: string | null;
  image: {
    url: string | null;
  };
}

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const sid = queryParams.get("station");
  const tColor =
    queryParams.get("textColor") !== null
      ? queryParams.get("textColor")
      : "000000";
  const bColor =
    queryParams.get("bg") !== null ? queryParams.get("bg") : "ffffff";
  const fs = queryParams.get("fs") !== null ? queryParams.get("fs") : "18px";
  const p = queryParams.get("p") !== null ? queryParams.get("p") : "12px";
  const lh = queryParams.get("lh") !== null ? queryParams.get("lh") : "1em";

  const sendPostMessage = () => {
    let height;
    setTimeout(function () {
      const iframe = document.getElementById("mrstream");
      if (iframe !== null) {
        height = iframe;

        window.parent.postMessage(
          {
            frameHeight: height,
            eventName: "mrstream",
          },
          "*"
        );
      }
    }, 10);
  };
  const styles: CSS.Properties = {
    backgroundColor: bColor === null ? "#ffffff" : `#${bColor}`,
    color: tColor === null ? "#000000" : `#${tColor}`,
    fontSize: fs === null ? "18px" : fs,
    paddingTop: p === null ? "12px" : p,
    paddingBottom: p === null ? "12px" : p,
    lineHeight: lh === null ? "1em" : lh,
  };
  const ref = useRef(null);

  const [track, setTrack] = useState(ONESECOND);
  const [station, setStation] = useState<Station | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [trigger, setTriggered] = useState(false);
  const [program, setProgram] = useState<Program | undefined>(undefined);

  useEffect(() => {
    const getStation = async () => {
      let url = `https://app.myradio.click/api/public/station/${sid}/onair`;
      try {
        let res = await fetch(url);
        const results = await res.json();
        setStation(results.station);
        setProgram(results.data.slot.program);
        setLoading(false);
        sendPostMessage();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (!station && loading === false) {
      console.log("Updated");
      setLoading(true);
      getStation();
    }

    if (!sid) {
      alert("No station id specified");
    }
  }, [setStation, setProgram, sid, station, loading, setLoading]);

  const playTrack = () => {
    setPlaying(!playing);
  };

  const togglePlay = async () => {
    if (station) {
      if (track === ONESECOND) {
        if (station.icecast_stream !== undefined)
          setTrack(station.icecast_stream);
      }
    }
    if (station && trigger === false) {
      setTriggered(true);
      setPlaying(true);
      setTimeout(playTrack, 1000);
    } else {
      setPlaying(!playing);
    }
  };

  // return <StationComponent station={station:Station} />;
  return (
    <div className={`flex`} style={styles} id="mrstream">
      {station && (
        <ReactPlayer
          ref={ref}
          playing={playing}
          controls={false}
          height={0}
          width={0}
          url={track}
          light={false}
          autoPlay={true}
          config={{
            file: {
              attributes: { preload: "auto" },
              forceAudio: true,
            },
          }}
        />
      )}
      {loading === true ? (
        <LoadingIndicator styles={styles} />
      ) : (
        <div className="flex gap-4 justify-center items-center">
          <button onClick={() => togglePlay()} className={`py-1 ml-4`}>
            {playing === true ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{
                  height: fs !== null ? fs : "18px",
                  width: fs !== null ? fs : "18px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                style={{
                  height: fs !== null ? fs : "18px",
                  width: fs !== null ? fs : "18px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            )}
          </button>
          <p>
            ON AIR:{" "}
            <span className="font-bold">
              {program && program.website_url ? (
                <a href={program && program.website_url} target="parent">
                  {program && program.name}
                </a>
              ) : (
                program && program.name
              )}
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

export default App;
